export const colors = {
    LandingPage_Background: '#FFFFFF',
    LandingPage_Description: '#373737',
    Button_Primary: '#006CA5',
    Button_Secondary: '#006CA5', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    Black: '#000000',
    Header: '#006CA5',
    Header_Text: "#FFFFFF",
    Header_Text_back: "#4C5152",
    Table_Header: '#BFCACD',
    Action_Button_Back: '#006CA5',
    ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
    ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
    HEADER: '#006CA5',
    BORDER_BACKGROUND: "#d6d6d6",
    CAR_BOX_EVEN:'#DEDFD5',
    CAR_BOX_ODD:'#006CA5'
}