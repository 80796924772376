export const FirebaseConfig = {
	"projectId": "circletrade-dc56d",
	"appId": "1:901190417310:web:39cb298b82055a6cb2748f",
	"databaseURL": "https://circletrade-dc56d-default-rtdb.firebaseio.com",
	"storageBucket": "circletrade-dc56d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC1IqY0yUGGtHTgaAibzJ4e-SUSqwjRyw0",
	"authDomain": "circletrade-dc56d.firebaseapp.com",
	"messagingSenderId": "901190417310",
	"measurementId": "G-YFNJX30NJ0"
};